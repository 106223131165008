import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import styles from '../styles/Home.module.css'
import HnReader from "../components/HnReader";
import Link from "next/link";
import {HnClient, IStoryWithAllComments} from "../util/HnClient";

interface IHomePageProps {
  initialStories?: IStoryWithAllComments[]
}

const Home: NextPage = (props: IHomePageProps) => {
  return (
    <div className={styles.container}>
      <Head>
        <title>HN Reader</title>
      </Head>

      <HnReader initialStories={props.initialStories}/>
    </div>
  )
}


export async function getServerSideProps() {
  const hnClient = new HnClient();

  // console.time('getTopStoriesWithAllComments')
  // const topStoriesWithAllComments = await hnClient.getTopStoriesWithAllComments();
  // console.timeEnd('getTopStoriesWithAllComments')

  console.time('getTopStories 1')
  const topStoriesWithAllComments = await hnClient.getTopStories(0, 30);
  // const topStoriesWithAllComments = await hnClient.getTopStoriesWithAllComments(0, 10);
  console.timeEnd('getTopStories 1')
  return {
    props: {
      initialStories: topStoriesWithAllComments
    }
  }
}

export default Home
