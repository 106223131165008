import {IStoryWithAllComments} from "../util/HnClient";
import moment from "moment";
import {timeFromNow} from "../util/TimeUtil";
import DOMPurify from "dompurify";


interface IProps {
  story: IStoryWithAllComments
  index: number
}

const Story = ({story, index}: IProps) => {
  const hasComments = story.isFullyLoaded
  let numComments = story.totalComments
  let commentLink = `/item/${story.id}`
  let commentLinkText
  if (hasComments) {
    commentLinkText = `${numComments} ${numComments == 1 ? 'comment' : 'comments'}`
  } else {
    commentLinkText = "loading comments"
  }
  let commentsLink = <a href={commentLink}>{commentLinkText}</a>
  let byLink = `https://news.ycombinator.com/user?id=${story.by}`;

  let displayUrl = story.url
  if (displayUrl) {
    displayUrl = displayUrl.replace('https://', '')
    displayUrl = displayUrl.replace('http://', '')
  }

  return (
      <div className="story">
        <div className="storyUpper"><div className="storyIndex">{index}.</div> <a rel="noreferrer"
                                                                                  href={story.url}>{story.title}</a>
        </div>
        <div className="storyLower">
          <div><a className="storyLinkWithText" href={story.url}>({displayUrl})</a></div>
          <div>
            {story.score} points by <a rel="noreferrer" href={byLink}>{story.by}</a> {timeFromNow(story.time)} | {commentsLink}
          </div>
        </div>
      </div>
  )
}
export default Story