import HnClient, {IStoryWithAllComments} from "../util/HnClient";
import {useEffect, useRef, useState} from "react";
import Story from "./Story";
import HNClientInstance from "../util/HnClient";
import {setLogger} from "@grpc/grpc-js";
import hnClient from "../util/HnClient";

export interface IProps {
  initialStories?: IStoryWithAllComments[]
}


const HnReader = (props: IProps) => {
  const initialLoad = 30
  const numToLoad = 30

  const client = HNClientInstance
  const loading = useRef(false)
  const [stories, setStories] = useState<IStoryWithAllComments[]>(props.initialStories ? props.initialStories : [])

  // console.log(`initial storeis: ${props.initialStories}`)

  useEffect(() =>  {
    const fullyLoaded = Promise.all(stories.map(s => hnClient.fullyLoadStory(s.id)))
    fullyLoaded.then(fullyLoadedStories => setStories(e => fullyLoadedStories))
  }, [])

  const getLastId = () => {
    console.log(`num stories: ${stories.length}`)
    if (stories.length) {
      return stories[stories.length - 1].id
    }
    return undefined
  }

  const loadMore = (numToLoad: number) => {
    console.log(`call to load more: ${numToLoad}`)
    if (loading.current) {
      return
    }
    loading.current = true
    console.log(`loading.... ${numToLoad}`)
    console.time('getTopStories')
    return client.getTopStoriesWithAllComments(stories.length, numToLoad).then(s => {
      console.timeEnd('getTopStories')
      console.log(`setting stories`)
      setStories(e => {
        return [...e, ...s]
      })
      loading.current = false
      // loadMore(numToLoad)
    })
  }



  return (
      <div>

        {stories && stories.map((story, i) => {
          return <Story key={story.id} index={i + 1} story={story}/>
        })}


        <button onClick={() => loadMore(numToLoad)}>Load More</button>

        <br/><br/><br/>
      </div>
  )

}


export default HnReader