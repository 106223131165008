import * as firebase from "firebase/app";
import {
  get,
  getDatabase,
  startAfter,
  limitToFirst,
  query,
  ref,
  DatabaseReference,
  DataSnapshot,
  Database
} from "firebase/database";
import {isAwaitExpression} from "tsutils";


interface IItem {
  id: number
  type: string
}

interface IStory extends IItem {
  by: string
  descendants: number
  kids: number[]
  score: number
  text: string
  time: number
  title: string
  type: 'story'
  url: string
}

export interface IComment extends IItem {
  by: string
  type: 'comment'
  kids: number[]
  parent: number
  text: string
  time: number
}

export interface IHasLoadedComments {
  loadedKids: ILoadedCommentTree[]
}

export interface ILoadedCommentTree extends IComment, IHasLoadedComments {}

export interface IStoryWithAllComments extends IStory, IHasLoadedComments {
  isFullyLoaded: boolean
  totalComments: number
}

export class HnClient {
  private db: Database
  private topStories: DatabaseReference;
  private topStoryIds: Promise<[number]>;

  constructor() {
    const app = firebase.initializeApp(
        {databaseURL: 'https://hacker-news.firebaseio.com'})
    this.db = getDatabase(this.app)
    this.topStories = ref(this.db, 'v0/topstories')
    // 500 top story ids
    this.topStoryIds = this.getTopStoryIds().then(i => i.val())
  }

  getTopStoryIds(limit = 1000) {
    return get(query(this.topStories, limitToFirst(limit)))
  }

  getTopStories(startingAfter: number = 0, limit = 10): Promise<IStory[]> {
    return this.topStoryIds.then(ids => ids.splice(startingAfter, limit))
    .then(stories => {
      return Promise.all(stories.map(story => this.getItem(story)))
    })
  }

  getTopStoriesWithAllComments(startingAfter: number = 0, limit = 30): Promise<IStoryWithAllComments[]> {
    return this.topStoryIds.then(ids => ids.splice(startingAfter, limit))
    .then(stories => {
      return Promise.all(stories.map(story => this.fullyLoadStory(story)))
      // return Promise.all(stories.map(story => this.getItem(story)))
    })
  }

  async fullyLoadStory(storyId: number): Promise<IStoryWithAllComments> {
    const story: IStory = await this.getItem(storyId)
    if (!story.kids) {
      return {
        ...story,
        loadedKids: [],
        totalComments: 0,
        isFullyLoaded: true
      }
    }
    const loadedKids = await Promise.all(story.kids.map(kid => this.loadAllComments(kid)))
    const storyWithLoadedKids = {
      ...story,
      loadedKids
    }
    return {
      ...storyWithLoadedKids,
      totalComments: this.countAllComments(storyWithLoadedKids),
      isFullyLoaded: true
    };
  }

  private countAllComments(story: IHasLoadedComments): number {
    let total = story.loadedKids.length
    for (let i = 0; i < story.loadedKids.length; i++) {
      total += this.countAllComments(story.loadedKids[i])
    }
    return total
  }

  private async loadAllComments(id: number): Promise<ILoadedCommentTree> {
    const item = await this.getItem(id)
    if (!item) {
      // console.log(`empty item: ${id}`)
      return {
        ...item,
        loadedKids: []
      }
    }
    if (!item.kids) {
      return {
        ...item,
        loadedKids: []
      }
    }
    const loadedKids = await Promise.all(item.kids.map(kid => this.loadAllComments(kid)))
    return {
      ...item,
      loadedKids
    }
  }

  // getTopStories(startingAfter, limit) {
  //   let queries = [
  //     startingAfter ? startAfter(startingAfter, 'id') : undefined,
  //     limitToFirst(limit)
  //   ].filter(x => x !== undefined)
  //   const query1 = query(this.topStories, ...queries);
  //   return get(query1)
  //   .then(i => {
  //     return i.val()
  //   }).catch(e => {
  //     console.log(e)
  //   })
  //   .then(stories => {
  //
  //     return Promise.all(stories.map(story => this.getItem(story)))
  //   })
  // }



  // getTopStories(start = 0, limit = 50) {
  //   return get(query(this.topStories, startAfter(start), limitToFirst(50))).then(i => i.val());
  // }

  getItem(itemId) {
    return get(ref(this.db, `v0/item/${itemId}`)).then(i => i.val())
  }

  getKids(itemId) {
    return this.getItem(itemId).then(item => {
      let kids = item.kids.map(kidId => {
        return this.getItem(kidId)
      })
      return Promise.all(kids).then(all => {
        return all.map(k => k).filter(k => !k.dead && !k.deleted)

      })

    }).catch(e => {
      console.log(e)
    })
  }
}

const HNClientInstance = new HnClient()
export default HNClientInstance
